body {
  background-color: #f5f5f5;
  padding-bottom: 60px;
}

#fixed-sor-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

#fixed-keyboard-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
}

#toggle-keyboard-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1001;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#sor-keyboard-wrapper {
  padding: 10px;
}