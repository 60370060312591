body {
  background-color: #f5f5f5;
  padding-bottom: 60px;
}

#fixed-sor-keyboard {
  background-color: #f0f0f0;
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 5px #0000001a;
}

#fixed-keyboard-container {
  z-index: 1000;
  background-color: #f0f0f0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 5px #0000001a;
}

#toggle-keyboard-button {
  z-index: 1001;
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#sor-keyboard-wrapper {
  padding: 10px;
}
/*# sourceMappingURL=index.b3c84b0e.css.map */
